<h1 *ngIf="data.title !== undefined" mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <table class="table_alert_dialog">
    <tr>
      <td>
        <mat-icon class="icon_alert" [style.color]="data.iconColor">{{data.icon || 'info'}}</mat-icon>
      </td>
      <td>
        {{data.message}}
      </td>
    </tr>
  </table>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button class="mat-button-base" [mat-dialog-close]="true" cdkFocusInitial>Aceptar</button>
</div>
