/**Entidad de los animales*/
export class AnimalModel {
  animalId: number;
  number: number;
  breedCategoryId: number;
  breedId: number;
  animalCategoryId: number;
  //pregnancyTypeId: number;
  //pregnancyDate: Date;
  rodeoId: number;
  isCow: boolean;
  isCastrated: boolean;
  weight: number;
  birthdate: Date;
  inactiveDate: Date;
  pregnancyDates: PregnancyDatesModel[]
}

/**Entidad de las categorías de animales*/
export class AnimalCategoryModel {
  animalCategoryId: number;
  isCow: boolean;
  isCastrated: boolean;
  name: string;
  monthsFrom: number;
}

/**Entidad de las fechas de preñez*/
export class PregnancyDatesModel {
  animalId: number;
  pregnancyDate: Date;
  //pregnancyTypeId: number;
}

/**Entidad de los tipos de ambarazo de las vacas*/
export class PregnancyTypeModel {
  pregnancyTypeId: number;
  name: string;
}

/**Campos */
export enum AnimalFields {
  /**Identificador*/
  animalId = 0,
  /**Número*/
  number = 1,
  /**Raza*/
  breed = 2,
  /**Category*/
  animalCategory = 3,
  /**Estado*/
  pregnancyType = 4,
  /**Peso*/
  weight = 5,
  /**Número de rodeo*/
  rodeo = 6,
  /**Fecha de baja*/
  inactiveDate = 7
}

/**Clase para definir los valores de los filtros */
export class AnimalFilter {
  /**Filtro para el establecimiento*/
  establishmentId: number;
  /**Filtro para el campo "pregnancyTypeId"*/
  pregnancyTypeId: number[];
  /**Filtro para el campo "AnimalCategoryId"*/
  animalCategoryId: number[];
  /**Filtro para el campo "CowStateId"*/
  rodeoId: number[];
  /**Filtro para el campo "AnimalCategoryId"*/
  breedId: number[];

  paddockId: number;
}
