import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegionModel, RegionFilter, RegionFields } from '../models/region.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';

/**Clase para el manejo de las regiones */
@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de regiones activas
   */
  listAll(): Promise<RegionModel[]> {
    return this.http.get<RegionModel[]>('/api/region')
      .toPromise();
  }

  /**
  * Devuelve la lista de las regiones
  * @param from Registro desdel cual obtener los datos
  * @param length Cantidad de registros a obtener
  * @param order Campo a utilizar para el orden
  * @param orderAsc Establece si el orden es ascendente
  * @param filter Filtros a utilizar
  * @param draw Identificador de la llamada
  */
  list(from: number, length: number, order: RegionFields, orderAsc: boolean, filter: RegionFilter, draw?: number): Promise<ResponseDataModel<RegionModel>> {
    const data = new QueryDataModel<RegionFilter, RegionFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<RegionModel>>('/api/region/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina una región
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/region/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos de una región
   * @param id Identificador
   */
  get(id: number): Promise<RegionModel> {
    return this.http.get<RegionModel>('/api/region/' + id)
      .toPromise();
  }

  /**
   * Graba los datos de una región
   * @param data Datos a guardar
   */
  save(data: RegionModel): Promise<boolean> {
    return this.http.put<boolean>('/api/region', data)
      .toPromise();
  }

  /**devuelve la lista de regiones activas para mostrar en los filtros del dashboard */
  listForDashboard(): Promise<RegionModel[]> {
    return this.http.get<RegionModel[]>('/api/region/dashboard/list')
      .toPromise();
  }
}
