import { Component, AfterViewInit, ViewChild, OnInit, EventEmitter, OnDestroy, Input } from '@angular/core';
import { of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, filter } from 'rxjs/operators';
import { Chart } from 'chart.js';
import { PlanningRainService } from '../../services/planning-rain.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../app.settings';
import { PlanningRainModel, PlanningRainDashboardFilter } from '../../models/planning-rain.model';

@Component({
  selector: 'dashboard-rains',
  templateUrl: './rains.component.html',
  styleUrls: ['./rains.component.scss']
})
export class RainsComponent implements OnDestroy, OnInit {

  chart: Chart = null;
  refresh = new EventEmitter();
  subs: Subscription;
  resultsLength = 0;
  isLoadingResults = false;
  hasError = false;
  draw: number;
  data: PlanningRainModel[] = null;
  months = Constants.MONTHS;
  filter = new PlanningRainDashboardFilter();

  constructor(
    private planningRainService: PlanningRainService,
    private snackBar: MatSnackBar,
  ) { }

  @Input('establishmentId')
  set paramEstablishment(val: number) {
    if (val !== this.filter.establishmentId) {
      this.filter.establishmentId = val;
      this.refreshData();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  refreshData() {
    this.refresh.emit();
  }

  ngOnInit() {
    this.initChart();
    //Capturo los eventos del filtro y, si está en otra página que no
    //sea la primera, lo muevo a la primera y cancelo el observable
    this.subs = this.refresh
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.draw = (new Date()).getTime();
          return this.planningRainService.getListForDashboard(this.filter);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.hasError = false;
          return data;
        }),
        catchError(() => {
          this.hasError = true;
          this.snackBar.open(Constants.ERROR_COMM);
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data as PlanningRainModel[];
        this.updateChart();
      });
  }


  /*Inicializa el chart (debe hacerse así porque sino no puede obtener el contexto) */
  initChart() {
    if (this.chart !== null)
      return;
    setTimeout(() => {
      Chart.defaults.global.defaultFontFamily = 'Lato';
      Chart.defaults.global.defaultFontSize = 14;

      this.chart = new Chart('rain-chart', {
        type: 'line',
        data: {
          datasets: [
            {
              label: 'Real',
              data: [],
              backgroundColor: '#ff9800',
              borderColor: '#ff9800',
              fill: false
            },
            {
              label: 'Estimated',
              data: [],
              backgroundColor: '#e51c23',
              borderColor: '#e51c23',
              fill: false
            }
          ],
          labels: [],
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true
          },
          scales: {
            xAxes: [{
              display: true
            }],
            yAxes: [{
              display: true
            }],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return ' ' + (tooltipItem.yLabel as number) + ' mm';
              }
            }
          }
        }
      });
      this.updateChart();
    }, 100);
  }

  /**Actualiza los datos del gráfico */
  updateChart() {
    if (this.data === null || this.chart === null)
      return;
    this.chart.data.datasets[0].data = this.data.map(x => x.real);
    this.chart.data.datasets[1].data = this.data.map(x => x.estimated);
    this.chart.data.labels = this.data.map(x => (this.months[x.month % 100 - 1]) + ' ' + Math.floor(x.month / 100));
    this.chart.update();
  }
}
