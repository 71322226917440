import { MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatPaginatorDefaultOptions } from "@angular/material/paginator";
import { MatTooltipDefaultOptions } from "@angular/material/tooltip";

export class Constants {
  public static TITLE = 'SGI';

  /**Valores por defecto para los snackbar */
  public static SNACKBAR_DEFAULTS: MatSnackBarConfig = { duration: 3000 };

  /**Valores por defecto para el delay de los tooltips */
  public static TOOLTIP_DEFAULTS: MatTooltipDefaultOptions = { showDelay: 500, hideDelay: 100, touchendHideDelay: 500 };

  /**Valores por defecto del paginador */
  public static PAGINATOR_DEFAULTS: MatPaginatorDefaultOptions = { showFirstLastButtons: true, pageSizeOptions: [8, 20, 50] };

  public static MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  public static DATE_LOCALE = 'es-AR';
  public static REGEX_URL = /^(https?):\/\/(-\.)?([^\s\\/?\.#-%=]+\.?)+(\/[^\s]*)?$/;
  public static REGEX_NUMBER_NATURAL = /^[0-9]*$/;
  public static REGEX_NUMBER_NATURAL_NEGATIVE = /^-?[0-9]*$/;
  public static REGEX_NUMBER_TWO_DECIMALS = /^[0-9]+(.[0-9]{0,2})?$/;
  public static REGEX_NUMBER_TWO_DECIMALS_NEGATIVE = /^-?[0-9]+(.[0-9]{0,2})?$/;
  public static REGEX_NUMBER_FOUR_DECIMALS = /^[0-9]+(.[0-9]{0,4})?$/;
  public static REGEX_NUMBER_FOUR_DECIMALS_NEGATIVE = /^-?[0-9]+(.[0-9]{0,4})?$/;
  public static REGEX_NUMBER_EIGHT_DECIMALS = /^[0-9]+(.[0-9]{0,8})?$/;
  public static REGEX_PERCENT = Constants.REGEX_NUMBER_TWO_DECIMALS;
  public static INT32_MAX = 2147483648;
  public static INT16_MAX = 32768;

  /**Constantes de los mapas */
  public static BING_MAPS_KEY = 'AsQg-7ybX_1HoCHr8VOAf4KoR0LCKa7DfAvhF9NXf5dQSZwaNMXlMZSpv4XP4s6h';
  public static DEFAULT_COORDS = [-61, -38];
  public static DEFAULT_IMAGERY = 'AerialWithLabelsOnDemand';
  public static MAP_POINT_COLOR = [72, 176, 153, 1];
  public static MAP_PROJECTION = 'EPSG:4326';

  /**Mensajes de error */
  public static ERROR_COMM = 'Ha ocurrido un error en la comunicación con el servidor';
  public static ERROR_SAVING = 'Ha ocurrido un error al grabar los datos';
  public static ERROR_IMPORT = 'Ha ocurrido un error en la importación de datos';

  /**Constantes de notificaciones de las actividades */
  public static NOTIFICACION_DAYS = 10;

  /**Constantes de Establecimiento */
  public static PPNAF = 0.6;
  public static CF = 0.6;
  public static A = 133.3;
  public static B = 1.762;
  public static CICLO_ESTRAL = 21;


  /**Constantes de planifiación */
  public static TEMPERATURE_JANUARY = 3;
  public static TEMPERATURE_FEBRUARY = 3;
  public static TEMPERATURE_MARCH = 1;
  public static TEMPERATURE_APRIL = 1;
  public static TEMPERATURE_MAY = 1;
  public static TEMPERATURE_JUNE = 2;
  public static TEMPERATURE_JULY = 2;
  public static TEMPERATURE_AUGUST = 2;
  public static TEMPERATURE_SEPTEMBER = 2;
  public static TEMPERATURE_OCTOBER = 2;
  public static TEMPERATURE_NOVEMBER = 1;
  public static TEMPERATURE_DECEMBER = 1;

  public static WEIGHT_GAIN_JANUARY = 10;
  public static WEIGHT_GAIN_FEBRUARY = 10;
  public static WEIGHT_GAIN_MARCH = 10;
  public static WEIGHT_GAIN_APRIL = 10;
  public static WEIGHT_GAIN_MAY = 10;
  public static WEIGHT_GAIN_JUNE = 10 ;
  public static WEIGHT_GAIN_JULY = -1;
  public static WEIGHT_GAIN_AUGUST = 0.5445;
  public static WEIGHT_GAIN_SEPTEMBER = 0;
  public static WEIGHT_GAIN_OCTOBER = 10;
  public static WEIGHT_GAIN_NOVEMBER = 10;
  public static WEIGHT_GAIN_DECEMBER = 10;

}
