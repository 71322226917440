<div class="spinner-container" *ngIf="working">
  <mat-spinner></mat-spinner>
</div>
<h1 mat-dialog-title>Cambiar contraseña</h1>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="passwordForm">
    <mat-form-field>
      <input matInput cdkFocusInitial placeholder="Contraseña actual" maxlength="50" [type]="hide ? 'password' : 'text'" formControlName="current">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field>
      <input matInput placeholder="Nueva contraseña" maxlength="50" [type]="hide ? 'password' : 'text'" formControlName="new">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field [class.mat-form-field-invalid]="passwordForm.hasError('notSame')">
      <input matInput placeholder="Repetir contraseña" maxlength="50" [type]="hide ? 'password' : 'text'" formControlName="repeat">
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </form>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button mat-button (click)="changePassword();">Guardar</button>
</div>
