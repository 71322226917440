import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlanningRainModel, PlanningRainFilter, PlanningRainDashboardFilter} from '../models/planning-rain.model';

/**Resultado de la grabación */
export enum SaveResult {
  /**Se grabó todo bien 👍 */
  Ok = 0
}

/**Clase para el manejo de las lluvias por planificación*/
@Injectable({
  providedIn: 'root'
})
export class PlanningRainService {

  constructor(private http: HttpClient) { }
  /**
   * lista con la información de las lluvias para los meses de la planificación
   * @param filter filtros
   */
  list(filter: PlanningRainFilter): Promise<PlanningRainModel> {
    return this.http.post<PlanningRainModel>('/api/planningrain/list', filter)
      .toPromise()
      .then(data => { return data; });
  }

  /**
   * Devuelve los datos de lluvias de una planificación
   * @param planningId Identificador de la planificación
   */
  get(planningId: number): Promise<PlanningRainModel> {
    return this.http.get<PlanningRainModel>('/api/planningrain/' + planningId)
      .toPromise();
  }

  /**
   * Graba los datos de lluvias de una planificación
   * @param data Datos a guardar
   */
  save(data: PlanningRainModel): Promise<SaveResult> {
    return this.http.put<SaveResult>('/api/planningrain', data)
      .toPromise();
  }
  /**
   * obtiene la info para mostrar en el dashboard
   * @param filter clase con los filtros
   */
  getListForDashboard(filter: PlanningRainDashboardFilter): Promise<PlanningRainModel> {
    return this.http.post<PlanningRainModel>('/api/planningrain/dashboard/list', filter)
      .toPromise()
      .then(data => { return data; });
  }

  /**
 * Obtiene el template de excel para importar
 * @param data template excel
 */
  Import(fileToUpload: File, planningId: number): Promise<any> {
    const _formData = new FormData();
    _formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post('/api/planningrain/import/' + planningId, _formData, { responseType: 'blob' })
      .toPromise()
  }

}
