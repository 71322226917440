<h3>Lluvias</h3>
<div class="chart-container">
  <div class="loading-shade"
       *ngIf="isLoadingResults || hasError">
    <mat-spinner *ngIf="isLoadingResults && !hasError"></mat-spinner>
    <div *ngIf="hasError" class="error mat-typography">
      Ha ocurrido un error
      <div class="retry-grid" (click)="refresh.emit()"><mat-icon matSuffix>refresh</mat-icon>reintentar</div>
    </div>
  </div>
  <canvas #canvas id="rain-chart"></canvas>
</div>
