<h3>Progreso</h3>
<div class="content">
  <div class="loading-shade"
       *ngIf="isLoadingResults || hasError">
    <mat-spinner *ngIf="isLoadingResults && !hasError"></mat-spinner>
    <div *ngIf="hasError" class="error mat-typography">
      Ha ocurrido un error
    </div>
  </div>

  <div fxLayout="row wrap">
    <p fxFlex="10">{{startDate | date}}</p>
    <mat-progress-bar fxFlex="80" mode="determinate" [value]="progress"></mat-progress-bar>
    <!-- <span class="progress_bar" *ngIf="progress" [ngStyle]="{'left': (progress<50?progress:50) + '%'}">{{progress | number:'1.0-0'}}%</span> -->
    <span class="progress_bar" *ngIf="progress">{{progress | number:'1.0-0'}}%</span>
    <span *ngIf="!progress" class="progress_barSI">Sin información</span>
    <p fxFlex="10" class="end">{{endDate | date}}</p>
  </div>


</div>
