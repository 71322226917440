import { Component, AfterViewInit, ViewChild, EventEmitter, OnDestroy, Input, Output } from '@angular/core';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, filter } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlanningRainFilter, PlanningRainModel } from '../models/planning-rain.model';
import { PlanningService } from '../services/planning.service';
import { PlanningRainService } from '../services/planning-rain.service';
import { Constants } from '../app.settings';
import { ExcelService } from '../services/excel.service';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnDestroy, AfterViewInit {
  isLoadingResults = false;
  hasError = false;
  subs: Subscription;
  refresh = new EventEmitter();
  filter = new PlanningRainFilter();
  draw: number;
  data: PlanningRainModel[] = [];
  repValue: string;
  constructor(
    private rainService: PlanningRainService,
    private planningService: PlanningService,
    private reportService: ReportService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    this.subs = merge(this.refresh)
      .pipe(
        startWith({}),
        filter(() => typeof this.filter.planningId !== 'undefined' && this.filter.planningId !== null && this.filter.planningId !== 0),
        switchMap(() => {
          this.isLoadingResults = true;
          this.draw = (new Date()).getTime();
          return this.rainService.list(this.filter);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.hasError = false;
          return data
        }),
        catchError(() => {
          this.hasError = true;
          this.snackBar.open(Constants.ERROR_COMM);
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data = data as PlanningRainModel[];
     
      });
  }

  ngOnDestroy() {
    
  }

  
  /*Descarga el Excel */
  downloadExcel(reportId: string): void {
    let reportName:string = '';
    switch (reportId) {
      case "1":
        reportName = 'Reporte de follaje';
        break;
      case "2":
        reportName = 'Reporte de ganado preñado';
        break;
      case "3":
        reportName = 'Reporte de ganado';
        break;
      default:
        return;
    }
    this.reportService.getToExcel(reportId)
      .then((result) => {
        this.excelService.reportsExportToExcel(result, reportName + '.xlsx', 'Datos');
      })
      .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }

}
