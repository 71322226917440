import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { UserRole } from './models/user.model';
import { RoleGuard } from './role.guard';
import { LoggedGuard } from './logged.guard';
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [
  //{
  //  path: '',
  //  redirectTo: '/home',
  //  pathMatch: 'full'
  //},
  {
    path: '',
    component: DashboardComponent,
    canActivate: [LoggedGuard]
  },
  {
    path: 'maestros',
    loadChildren: () => import('./crud/crud.module').then(mod => mod.CrudModule),
    data: { roles: [UserRole.Admin, UserRole.AdminUsers, UserRole.User] },
    canActivate: [RoleGuard]
  },
  {
    path: 'reportes',
    component: ReportsComponent,
    pathMatch: 'full',
    canActivate: [LoggedGuard]
  },
  {
    path: 'establecimientos',
    loadChildren: () => import('./establishments/establishments.module').then(mod => mod.EstablishmentsModule),
    data: { roles: [UserRole.Admin, UserRole.User, UserRole.AssingEstablishment] },
    canActivate: [RoleGuard]
  },
  {
    path: 'planificaciones',
    loadChildren: () => import('./plannings/plannings.module').then(mod => mod.PlanningsModule),
    data: { roles: [UserRole.Admin] },
    canActivate: [RoleGuard]
  },
  { path: 'ingreso', component: LoginComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
