import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EstablishmentModel, EstablishmentFilter, EstablishmentFields } from '../models/establishment.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';
import { MapObjectModel } from '../models/map-object.model';

/**Clase para el manejo de los establecimientos */
@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de establecimientos del usuario
   */
  listAll(): Promise<EstablishmentModel[]> {
    return this.http.get('/api/establishment')
      .toPromise()
      .then(res => { return res as EstablishmentModel[] });
  }

  /**
  * Devuelve la lista de los establecimientos
  * @param from Registro desdel cual obtener los datos
  * @param length Cantidad de registros a obtener
  * @param order Campo a utilizar para el orden
  * @param orderAsc Establece si el orden es ascendente
  * @param filter Filtros a utilizar
  * @param draw Identificador de la llamada
  */
  list(from: number, length: number, order: EstablishmentFields, orderAsc: boolean, filter: EstablishmentFilter, draw?: number): Promise<ResponseDataModel<EstablishmentModel>> {
    const data = new QueryDataModel<EstablishmentFilter, EstablishmentFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<EstablishmentModel>>('/api/establishment/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina un establecimiento
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/establishment/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos del establecimiento
   * @param id Identificador
   */
  get(id: number): Promise<EstablishmentModel> {
    return this.http.get<EstablishmentModel>('/api/establishment/' + id)
      .toPromise();
  }

  /**
   * Graba los datos del establecimiento
   * @param data Datos a guardar
   */
  save(data: EstablishmentModel): Promise<boolean> {
    return this.http.put<boolean>('/api/establishment', data)
      .toPromise();
  }

  /**
   * Devuelve el alias o nombre del establecimiento
   * @param id Identificador
   */
  getName(id: number): Promise<string> {
    return this.http.get<string>('/api/establishment/' + id + '/name')
      .toPromise();
  }

  /**
   * Devuelve el alias o nombre del establecimiento
   * @param id Identificador
   */
  listMapObjects(id: number): Promise<MapObjectModel[]> {
    return this.http.get<MapObjectModel[]>('/api/establishment/' + id + '/map-objects')
      .toPromise();
  }
  
  /**
   * devuelve la lista de establecimientos correspondientes a una zona, para usar en los filtros del dashboard
   * @param zoneId identificador de la zona
   */
  listForDashboard(zoneId: number): Promise<EstablishmentModel[]> {
    return this.http.get<EstablishmentModel[]>('/api/establishment/' + zoneId + '/dashboard/list')
      .toPromise();
  }
}
