import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
//import { ContractReportsFields, ContractReportsFilter, ContractReportsModel } from '../model/contract-reports.model';
//import { QueryDataModel, ResponseDataModel } from '../model/api.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }


  getToExcel(reportId: string): Promise<any> {
    return this.http.get<any>("/api/report/toExcel/" + reportId)
      .toPromise();
  }
}
