import { Component, OnInit, Input } from '@angular/core';
import { PlanningService } from '../../services/planning.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Constants } from '../../app.settings';

@Component({
  selector: 'dashboard-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  progress: number = null;
  startDate: Date = null;
  endDate: Date = null;

  isLoadingResults = false;
  hasError = false;
  establishmentId: number;

  @Input('establishmentId')
  set paramEstablishment(val: number) {
    if (typeof val !== 'undefined' && val !== null && val !== this.establishmentId) {
      this.establishmentId = val;
      this.refreshData();
    }
  }

  constructor(
    private planningService: PlanningService,
    private snackBar: MatSnackBar
  ) { }

  refreshData() {
    this.isLoadingResults = true;
    this.planningService.getProgressForDashboard(this.establishmentId)
      .then(res => {
        this.progress = res ? res.progress : null;
        this.startDate = res ? res.from : null;
        this.endDate = res ? res.to : null;
      })
      .catch(() => this.hasError = true)
      .finally(() => this.isLoadingResults = false);
  }

  ngOnInit() {

  }
}
