<div class="grid">
  <!--mat-elevation-z8-->
  <mat-card class="capture-card">
    <div class="flex titles valign-wrapper">
      <i class="material-icons">dashboard</i><h2>Dashboard</h2>
      <mat-divider></mat-divider>
    </div>

    <div class="loading-shade"
         *ngIf="isLoadingResults || hasError">
      <mat-spinner *ngIf="isLoadingResults && !hasError"></mat-spinner>
      <div *ngIf="hasError" class="error mat-typography">
        Ha ocurrido un error
      </div>
    </div>

    <div class="filters">
      <form #filterForm="ngForm" class="flex" align="space-between" flex-wrap="wrap">
        <mat-form-field class="col3">
          <mat-select placeholder="Región" name="region" [(ngModel)]="regionId" (selectionChange)="changeRegion($event.value)">
            <mat-option [value]="">Todos</mat-option>
            <mat-option *ngFor="let region of regions" [value]="region.regionId">
              {{region.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col3">
          <mat-select placeholder="Zona" name="zone" [(ngModel)]="zoneId" (selectionChange)="changeZone($event.value)">
            <mat-option [value]="">Todos</mat-option>
            <mat-option *ngFor="let zone of zones" [value]="zone.zoneId">
              {{zone.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col3">
          <mat-select placeholder="Establecimiento" name="establishment" [(ngModel)]="establishmentId" (selectionChange)="changeEstablishment($event.value)">
            <mat-option [value]="">Todos</mat-option>
            <mat-option *ngFor="let establishment of establishments" [value]="establishment.establishmentId">
              {{establishment.aliasOrName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col3">
          <mat-select placeholder="Lote" name="paddock" [(ngModel)]="paddockId">
            <mat-option [value]="">Todos</mat-option>
            <mat-option *ngFor="let paddock of paddocks" [value]="paddock.establishmentId">
              {{paddock.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>

    <div *ngIf="establishmentId">
      <dashboard-progress-bar [establishmentId]="establishmentId"></dashboard-progress-bar>
      <dashboard-rains [establishmentId]="establishmentId"></dashboard-rains>
      <dashboard-availabilities [establishmentId]="establishmentId" [paddockId]="paddockId"></dashboard-availabilities>
      <dashboard-animals [establishmentId]="establishmentId" [paddockId]="paddockId"></dashboard-animals>
    </div>
  </mat-card>
</div>
