import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../services/user.service';
import { Constants } from '../app.settings';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  showLogin = false;
  @ViewChild('rememberMe') rememberMe: MatSlideToggle;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router) {

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });

    if (!this.userService.isLoggedIn())
      this.userService.updateUserFromServer().then(res => {
        if (res)
          this.router.navigate(['']);
        else
          this.showLogin = true;
      })
        .catch(() => this.snackBar.open(Constants.ERROR_COMM))
        .finally(() => this.loginForm.enable());
    else
      this.router.navigate(['']);
  }

  auth(): void {
    if (!this.loginForm.valid)
      return;
    this.loginForm.disable();
    this.userService.auth(this.loginForm.controls.username.value, this.loginForm.controls.password.value, this.rememberMe.checked)
      .then(res => {
        if (res)
          this.router.navigate(['']);
        else
          this.snackBar.open('El usuario o la contraseña ingresados son incorrectos');
      })
      .catch(() => this.snackBar.open(Constants.ERROR_COMM))
      .finally(() => this.loginForm.enable());
  }
}
