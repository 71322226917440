/**Entidad de asignaciones de lluvias */
export class PlanningRainModel {
  planningId: number;
  rains: RainValueModel[];

  month: number;
  estimated: number;
  real: number;
  establishmentName: string;
}
/**valores de las lluvias por mes */
export class RainValueModel {
  value: number;
  month: number;
}

/**Campos de la grilla*/
export enum PlanningRainFields {
  period = 0,
  estimated = 1,
  real = 2,
}

/**Clase para definir los valores de los filtros */
export class PlanningRainFilter {
  planningId: number;
}

/**clase que define los filtros para el dashboard */
export class PlanningRainDashboardFilter {
  regionId: number;
  zoneId: number;
  establishmentId: number;
}
