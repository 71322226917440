/**Entidad para la representación de una estimación de lluvias de una planificación */
export class AvailabilityModel {
  plannigId: number;
  month: number;
  rodedoId: number;
  paddockId: number;
  
  forageAccumulated: number;
  consumption: number;
  substitute: number;
  available: number;
  manualForageId: number;
  manualForageAmount: number;
  manualConsumptionId: number;
  manualConsumptionAmount: number;

  forageAccumulatedMcal: number;
  consumptionMcal: number;
  substituteMcal: number;
  availableMcal: number;
  manualForageIdMcal: number;
  manualForageAmountMcal: number;
  manualConsumptionIdMcal: number;
  manualConsumptionAmountMcal: number;
}


export class AvailabilityDashboardFilter {
  regionId: number;
  zoneId: number;
  establishmentId: number;
  paddockId: number;
}


export class AvailabilitySelection {
  planningId: number;
  paddockIds: number[];
}
