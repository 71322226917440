import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RodeoModel, RodeoFilter, RodeoFields } from '../models/rodeo.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';

/**Clase para el manejo de los rodeos */
@Injectable({
  providedIn: 'root'
})
export class RodeoService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de rodeos de un establecimiento
   */
  listAll(establishmentId: number): Promise<RodeoModel[]> {
    return this.http.get<RodeoModel[]>('/api/rodeo/list/' + establishmentId)
      .toPromise();
  }

  /**
   * Listado de rodeos activos de un establecimiento, incluyendo un rodeo en especial sin importar su estado
   */
  listIncluding(establishmentId: number, rodeoId): Promise<RodeoModel[]> {
    return this.http.get<RodeoModel[]>('/api/rodeo/list/' + establishmentId + '/including/' + rodeoId)
      .toPromise();
  }

  /**
   * Devuelve la lista de los rodeos
   * @param from Registro desdel cual obtener los datos
   * @param length Cantidad de registros a obtener
   * @param order Campo a utilizar para el orden
   * @param orderAsc Establece si el orden es ascendente
   * @param filter Filtros a utilizar
   * @param draw Identificador de la llamada
   */
  list(from: number, length: number, order: RodeoFields, orderAsc: boolean, filter: RodeoFilter, draw?: number): Promise<ResponseDataModel<RodeoModel>> {
    const data = new QueryDataModel<RodeoFilter, RodeoFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<RodeoModel>>('/api/rodeo/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina un rodeos
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/rodeo/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos del rodeo
   * @param id Identificador
   */
  get(id: number): Promise<RodeoModel> {
    return this.http.get<RodeoModel>('/api/rodeo/' + id)
      .toPromise();
  }

  /**
   * Graba los datos del rodeo
   * @param data Datos a guardar
   */
  save(data: RodeoModel): Promise<boolean> {
    return this.http.put<boolean>('/api/rodeo', data)
      .toPromise();
  }
}
