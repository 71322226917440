<div class="grid">
  <!--mat-elevation-z8-->
  <mat-card class="capture-card">
    <div class="flex titles valign-wrapper">
      <i class="material-icons">insert_drive_file</i><h2>Reportes</h2>
      <mat-divider></mat-divider>
    </div>

    <div class="loading-shade"
         *ngIf="false">
      <mat-spinner *ngIf="false"></mat-spinner>
      <div *ngIf="false" class="error mat-typography">
        Ha ocurrido un error
      </div>
    </div>

    <div class="filters">
      <form #filterForm="ngForm" align="space-between" flex-wrap="wrap">
        <mat-form-field class="col3">
          <mat-select placeholder="Tipo" #reportId name="reportId"  [(ngModel)]="repValue">
            <mat-option value="Seleccione un reporte"></mat-option>
            <mat-option value="1">Reporte de follaje</mat-option>
            <mat-option value="2">Reporte de ganado preñado</mat-option>
            <mat-option value="3">Reporte de ganado</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button (click)="downloadExcel(repValue)" >
          <mat-icon>cloud_download</mat-icon>
        </button>
      </form>
    </div>

  

  </mat-card>
</div>
