/**Entidad del usuario */
export class UserModel {
  userId: number;
  roles: UserRole[];
  username: string;
  isACtive: boolean;
  password: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
}

/**Modelo para representar los permisos de un usuario en un cliente asociado */
export class UserEstablishmentModel {
  /**Identificador del establecimiento*/
  establishmentId: number;
  /**Nombre del establecimiento*/
  name: string;
  /**Establece si el usuario puede crear planificaciones*/
  canCreatePlannings: boolean;
  /**establece si el usuario puede editar planificaciones*/
  canEditPlannings: boolean;
/**establece si el usuario es supervisor del establecimiento*/
  isSupervisor: boolean;
/**establece si el usuario puede administrar lluvias*/
  canAdminRains: boolean;
/**establece si el usuario puede administrar tactos*/
  canAdminExams: boolean;
/**establece si el usuario puede administrar rotaciones*/
  canAdminRotations: boolean;
}

/**Campos del usuario */
export enum UserFields {
  userId = 0,
  username = 1,
  firstName = 2,
  lastName = 3,
  isActive = 4,
  loggedOn = 6
}

/**Clase para definir los valores de los filtros */
export class UserFilter {
  /**Filtro para el campo "Username"*/
  username: string;
  /**Filtro para el campo "FirstName"*/
  firstName: string;
  /**Filtro para el campo "LastName"*/
  lastName: string;
  /**Filtro para el campo "IsActive"*/
  isActive: boolean;
  /**Búsqueda por texto libre*/
  freeText: string;
}

//Roles de los usuarios
export enum UserRole {
  Admin = 1,
  AdminUsers = 2,
  User = 3,
  AssingEstablishment = 4
}

/**Datos del usuario logeado */
export class MyUserModel {
  /**Nombre de usuario*/
  username: string;
  /**Identificador del usuario*/
  userId: number;
  /**Nombre del usuario*/
  name: string;
  /**Nombre del usuario*/
  firstName: string;
  /**URL a utilizar en la home*/
  homeUrl: string;
  /**Roles del usuario*/
  roleIds: UserRole[];
}
