import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import '@angular/common/locales/global/es-AR';

//Diálogos
import { ConfirmDialog } from './dialogs/confirm.dialog';
import { PasswordDialog } from './dialogs/password.dialog';
import { AlertDialog } from './dialogs/alert.dialog';

//Módulos
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';

//Componentes
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './login/login.component';
import { AuthInterceptor } from './http-interceptors/auth-interceptor';
import { AvailabilitiesComponent } from './dashboard/availabilities/availabilities.component';
import { ProgressBarComponent } from './dashboard/progress-bar/progress-bar.component';
import { RainsComponent } from './dashboard/rains/rains.component';
import { AnimalsComponent } from './dashboard/animals/animals.component';
import { ReportsComponent } from './reports/reports.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    NotFoundComponent,
    LoginComponent,
    ConfirmDialog,
    PasswordDialog,
    AlertDialog,
    ProgressBarComponent,
    RainsComponent,
    AvailabilitiesComponent,
    AnimalsComponent,
    ReportsComponent
  ],
  imports: [
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmDialog,
    PasswordDialog,
    AlertDialog
  ]
})
export class AppModule { }
