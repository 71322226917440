<h3>Animales</h3>

<div class="loading-shade"
     *ngIf="isLoadingResults || hasError">
  <mat-spinner *ngIf="isLoadingResults && !hasError"></mat-spinner>
  <div *ngIf="hasError" class="error mat-typography">
    Ha ocurrido un error
    <div class="retry-grid" (click)="refresh.emit()"><mat-icon matSuffix>refresh</mat-icon>reintentar</div>
  </div>
</div>

<div class="filters">
  <form #filterForm="ngForm" class="flex" align="space-between" flex-wrap="wrap">
    <mat-form-field fxFlex="49" fxFlex.xs="99">
      <mat-select placeholder="Rodeo" multiple name="rodeoId" [(ngModel)]="this.filter.rodeoId">
        <mat-option *ngFor="let rodeo of rodeos" [value]="rodeo.rodeoId">
          {{rodeo.name}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix (click)="$event.stopPropagation();this.filter.rodeoId=null">clear</mat-icon>
    </mat-form-field>
    <mat-form-field fxFlex="49" fxFlex.xs="99">
      <mat-select placeholder="Categoría" multiple name="animalCategoryId" [(ngModel)]="this.filter.animalCategoryId">
        <mat-option *ngFor="let animalCategory of animalCategories" [value]="animalCategory.animalCategoryId">
          {{animalCategory.name}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix (click)="$event.stopPropagation();this.filter.animalCategoryId=null">clear</mat-icon>
    </mat-form-field>
  </form>
</div>
<div class="table-container">
  <table mat-table [dataSource]="data">

    <ng-container matColumnDef="paddock">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Lote
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{element.paddock}}</td>
    </ng-container>

    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Categoría
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{element.category}}</td>
    </ng-container>

    <ng-container matColumnDef="rodeo">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Rodeo
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{element.rodeo}}</td>
    </ng-container>

    <ng-container matColumnDef="avgWeight">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Peso Promedio
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{element.avgWeight}} Kg</td>
    </ng-container>

    <ng-container matColumnDef="quantityInRodeo">
      <th mat-header-cell *matHeaderCellDef>
        <div>
          Cantidad
        </div>
      </th>
      <td mat-cell *matCellDef="let element">{{element.quantityInRodeo}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
