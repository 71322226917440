import { Injectable } from '@angular/core';
//import { Workbook } from 'exceljs'; ### No funciona de esta manera
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  /**
  * Agrega a un excel existente la información de las órdenes de venta
  * @param json Datos que se quieren agregar al excel
  * @param columns Definición de las columnas del reporte
  */
  public salesOrderAddToExistingExcel(json: any[], file: File): void {

    let wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      wb.xlsx.load(<ArrayBuffer>buffer).then(workbook => {
        for (let row of json) {
          let rowData = [];
          for (var key in row) {
            let val = row[key];
            rowData.push(val);
          }
          var r = workbook.getWorksheet(1).addRow(rowData);
          r.getCell(1).numFmt = 'd/m/yyyy hh:mm:ss ';
          //r.getCell(13).numFmt = 'd/m/yyyy';
          //r.getCell(14).numFmt = 'd/m/yyyy';
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, file.name);
        });
      });
    };
  }

  /**
 * Genera un archivo de Excel con los datos de la grilla de las órdenes de venta
 * @param json Datos del reporte
 * @param fileName Nombre del archivo
 * @param sheetName Nombre de la hoja del Excel
 * @param filters filtros
 * @param dom Referncia al DOM de componente que genera el reporte (se utiliza para obtener los filtros seleccionados)
 */
  public salesOrderExportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Título
    //let titleRow = sheet.addRow([title]);
    //titleRow.font = {
    //    name: 'Calibri',
    //    family: 4,
    //    size: 16,
    //    bold: true,
    //    color: { argb: 'FFFFFFFF' }
    //};
    //titleRow.fill = {
    //    type: 'pattern',
    //    pattern: 'solid',
    //    fgColor: { argb: 'FF2E2B70' }
    //};

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);
      r.getCell(1).numFmt = 'd/m/yyyy';
      //r.getCell(13).numFmt = 'd/m/yyyy';
      //r.getCell(14).numFmt = 'd-m-yyyy hh:mm:ss ';
      r.getCell(9).numFmt = '#,##0';
    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  /**
    * agrega a un excel existente información de las fijaciones
    * @param json Datos que se quieren agregar al excel
    * @param columns Definición de las columnas del reporte
    */
  public priceFixationAddToExistingExcel(json: any[], file: File): void {

    let wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer = reader.result;
      wb.xlsx.load(<ArrayBuffer>buffer).then(workbook => {
        for (let row of json) {
          let rowData = [];
          for (var key in row) {
            let val = row[key];
            rowData.push(val);
          }
          var r = workbook.getWorksheet(1).addRow(rowData);
        }
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, file.name);
        });
      });
    };
  }

  /**
 * Genera un archivo de Excel con los datos de un reporte
 * @param json Datos del reporte
 * @param fileName Nombre del archivo
 * @param sheetName Nombre de la hoja del Excel
 * @param filters filtros
 * @param dom Referncia al DOM de componente que genera el reporte (se utiliza para obtener los filtros seleccionados)
 */
  public pricefixationExportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);
    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  /**
 * Genera un archivo de Excel con los datos de un reporte
 * @param json Datos del reporte
 * @param fileName Nombre del archivo
 * @param sheetName Nombre de la hoja del Excel
 * @param filters filtros
 * @param dom Referncia al DOM de componente que genera el reporte (se utiliza para obtener los filtros seleccionados)
 */
  public settlementExportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);
    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  /**
* Genera un archivo de Excel con los datos del informe de contratos
* @param json Datos del reporte
* @param fileName Nombre del archivo
* @param sheetName Nombre de la hoja del Excel
* @param filters filtros
* @param dom Referncia al DOM de componente que genera el reporte (se utiliza para obtener los filtros seleccionados)
*/
  public reportsExportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };
    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);

    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  public pendingDeliveryReportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);
      r.getCell(9).numFmt = 'd/m/yyyy';
      r.getCell(10).numFmt = 'd/m/yyyy';
      r.getCell(12).numFmt = 'd/m/yyyy';
    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  public freightsReportToExcel(json: any[], fileName: string, sheetName: string, filters: string[][] = null): void {

    //Libro
    let workbook = new Excel.Workbook();
    let sheet = workbook.addWorksheet(sheetName);
    let skip = 1;

    //Filtros
    if (filters !== null) {
      //skip += 2 + filters.length;
      sheet.addRow([]);
      for (let filter of filters) {
        var r = sheet.addRow(filter);
        r.getCell(1).font = { bold: true };
        r.getCell(1).alignment = { horizontal: 'right' };
      }
      sheet.addRow([]);
    }

    //Creo las cabeceras
    let header = [];
    for (let row of json) {
      for (var key in row) {
        header.push(key.charAt(0).toUpperCase() + key.slice(1));
      }
      break;
    }
    let titleRow = sheet.addRow(header);
    titleRow.font = {
      name: 'Calibri',
      family: 4,
      size: 14,
      bold: true,
      color: { argb: 'FFFFFFFF' }
    };
    titleRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF2E2B70' }
    };
    titleRow.alignment = {
      vertical: 'middle',
      horizontal: 'center'
    };

    //Recorro los datos con la definición de las cabeceras
    for (let row of json) {
      let rowData = [];
      for (var key in row) {
        let val = row[key];
        rowData.push(val);
      }
      var r = sheet.addRow(rowData);
      r.getCell(9).numFmt = 'd/m/yyyy';
      r.getCell(11).numFmt = 'd/m/yyyy';
    }

    this.autofitColumns(sheet, skip);

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName);
    });
  }

  eachColumnInRange(ws, col1, col2, cb) {
    for (let c = col1; c <= col2; c++) {
      let col = ws.getColumn(c);
      cb(col);
    }
  }

  autofitColumns(ws, skip: number) { // no good way to get text widths
    skip = 0;
    this.eachColumnInRange(ws, 1, ws.columnCount, column => {
      let maxWidth = 10;
      let i = 0;
      column.eachCell(cell => {
        if (++i <= skip)
          return;

        if (!cell.isMerged && cell.value) { // doesn't handle merged cells

          let text = "";
          if (typeof cell.value != "object") { // string, number, ...
            text = cell.value.toString();
          } else if (cell.value.richText) { // richText
            text = cell.value.richText.reduce((text, obj) => text + obj.text.toString(), "");
          }

          // handle new lines -> don't forget to set wrapText: true
          let values = text.split(/[\n\r]+/);

          for (let value of values) {
            let width = value.length;

            if (cell.font && cell.font.bold) {
              width *= 1.3; // bolding increases width
            }

            maxWidth = Math.max(maxWidth, width);
          }
        }
      });

      maxWidth += 0.71; // compensate for observed reduction
      maxWidth += 1; // buffer space

      column.width = maxWidth;
    });
  }
}
