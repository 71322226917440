import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AnimalModel, AnimalFilter, AnimalFields, PregnancyTypeModel, AnimalCategoryModel } from '../models/animal.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';


/**Resultado de la grabación */
export enum SaveResult {
  /**Se grabó todo bien 👍 */
  Ok = 0,
  /**Ya existe otro animal con ese número en el establecimiento */
  AnimalExists = -1,
  /**La fecha de baja no puede ser menor a la fecha de nacimiento */
  InactiveDateLessThanBirthDate = -2,
  /**Si se ingresa Fecha de Preñez , se debe ingresar tipo de preñez y viceversa */
  PregancyDataRequired = -3,
  /**Si se ingresa Fecha de Preñez repetida */
  PregancyDataRepeated = -4,
  /**La fecha de preñez no puede ser menor a la fecha de nacimiento */
  PregancyDateBeforeBirthdate = -5
}


/**Clase para el manejo de los animales */
@Injectable({
  providedIn: 'root'
})
export class AnimalService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de tipos de embarazo
   */
  listPregnancyTypeModel(): Promise<PregnancyTypeModel[]> {
    return this.http.get<PregnancyTypeModel[]>('/api/animal/pregnancy-types')
      .toPromise();
  }

  /**
   * Devuelve la lista de categorías de los animales
   */
  listCategories(): Promise<AnimalCategoryModel[]> {
    return this.http.get<AnimalCategoryModel[]>('/api/animal/categories')
      .toPromise();
  }

  /**
   * Devuelve la lista de los animales
   * @param from Registro desdel cual obtener los datos
   * @param length Cantidad de registros a obtener
   * @param order Campo a utilizar para el orden
   * @param orderAsc Establece si el orden es ascendente
   * @param filter Filtros a utilizar
   * @param draw Identificador de la llamada
   */
  list(from: number, length: number, order: AnimalFields, orderAsc: boolean, filter: AnimalFilter, draw?: number): Promise<ResponseDataModel<AnimalModel>> {
    const data = new QueryDataModel<AnimalFilter, AnimalFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<AnimalModel>>('/api/animal/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina un animales
   * @param id Identificador
   */
  delete(id: number): Promise<null> {
    return this.http.delete<null>('/api/animal/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos del animal
   * @param id Identificador
   */
  get(id: number): Promise<AnimalModel> {
    return this.http.get<AnimalModel>('/api/animal/' + id)
      .toPromise();
  }

  /**
   * Graba los datos del animal
   * @param data Datos a guardar
   */
  save(data: AnimalModel): Promise<SaveResult> {
    return this.http.put<SaveResult>('/api/animal', data)
      .toPromise();
  }

  /**
  * Obtiene el template de excel para importar
  * @param data template excel
  */
  Import(fileToUpload: File,establishmentId: number): Promise<any> {
    const _formData = new FormData();
    _formData.append('file', fileToUpload, fileToUpload.name);
    //_formData.append('establishmentId', establishmentId);
    return this.http.post('/api/animal/import/' + establishmentId, _formData, { responseType: 'blob' })
      .toPromise()
  }

  /**
   * devuelve la información del progreso de la planificaion de un establecimiento para mostrar en el dashboard
   * @param establishmentId identificador del establecimiento
   */
  getForDashboard(filter: AnimalFilter): Promise<any> {
    return this.http.post<any>('/api/animal/dashboard', filter)
      .toPromise();
  }
}
