<mat-toolbar color="primary">
  <mat-toolbar-row>
    <a class="logo" href="/" style=" text-decoration:none;color: #000;font-size: 2em; padding: 10px;border-radius: 0 30% 30% 0;">
      <!--<img src="/App/assets/img/logo.png" />-->
      <h1>SGI</h1>
    </a>
    <span class="topmenu-spacer"></span>
    <ng-container *ngIf="userService.myUser != null ">
      <div class="menu-desktop" >
        <ng-container *ngIf="userService.hasRole([1,3,4])">
          <a mat-button [routerLinkActive]="['active']" [routerLink]="['/reportes']"><mat-icon>description</mat-icon>&nbsp; Reportes</a>
          <a mat-button [routerLinkActive]="['active']" [routerLink]="['/establecimientos']"><mat-icon>home_work</mat-icon>&nbsp; Establecimientos</a>
          <a mat-button [routerLinkActive]="['active']" [routerLink]="['/planificaciones']" *ngIf="userService.hasRole([1])"><mat-icon svgIcon="planning"></mat-icon>&nbsp; Planificaciones</a>
        </ng-container>
        <ng-container *ngIf="userService.hasRole([1,2,3])">
          <a mat-button [matMenuTriggerFor]="adminMenu"><mat-icon>settings</mat-icon> Datos maestros<mat-icon>arrow_drop_down</mat-icon></a>
          <mat-menu #adminMenu="matMenu">
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/razas']" *ngIf="userService.hasRole([1])"><mat-icon svgIcon="cow"></mat-icon>Razas</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/usuarios']"><mat-icon>people</mat-icon>Usuarios</a>
            <hr />
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/regiones']" *ngIf="userService.hasRole([1])"><mat-icon>place</mat-icon>Regiones</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/zonas']" *ngIf="userService.hasRole([1])"><mat-icon>place</mat-icon>Zonas</a>
          </mat-menu>
        </ng-container>
        <a mat-button [matMenuTriggerFor]="meMenu"><mat-icon>person</mat-icon> {{ userService.myName }}<mat-icon>arrow_drop_down</mat-icon></a>
        <mat-menu #meMenu="matMenu">
          <a mat-menu-item (click)="changePassword()"><mat-icon>lock</mat-icon> Cambiar contraseña</a>
          <a mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Salir</a>
        </mat-menu>
        <!--<button mat-button (click)="showHelp()"><mat-icon>help</mat-icon></button>-->
      </div>

      <!--Menu responsive-->
      <div id="mobile_menu" class="menu-mobile">
        <button mat-button [matMenuTriggerFor]="mobile_men">
          <mat-icon>menu</mat-icon>
        </button>

        <mat-menu #mobile_men="matMenu">
          <ng-container *ngIf="userService.hasRole([1,3,4])">
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/reportes']"><mat-icon>description</mat-icon>&nbsp; Reportes</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/establecimientos']"><mat-icon>home_work</mat-icon>&nbsp; Establecimientos</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/planificaciones']" *ngIf="userService.hasRole([1])"><mat-icon svgIcon="planning"></mat-icon>&nbsp; Planificaciones</a>
            <button *ngIf="userService.hasRole([1,2])" mat-menu-item [matMenuTriggerFor]="Setting2"><mat-icon>settings</mat-icon> Datos maestros</button>
            <button mat-menu-item [matMenuTriggerFor]="admin2Menu"><mat-icon>person</mat-icon> {{ userService.myName }}</button>
          </ng-container>
        </mat-menu>

        <mat-menu #Setting2="matMenu">
          <ng-container *ngIf="userService.hasRole([1,2,3])">
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/razas']" *ngIf="userService.hasRole([1])"><mat-icon svgIcon="cow"></mat-icon>Razas</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/usuarios']"><mat-icon>people</mat-icon>Usuarios</a>
            <hr />
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/regiones']" *ngIf="userService.hasRole([1])"><mat-icon>place</mat-icon>Regiones</a>
            <a mat-menu-item [routerLinkActive]="['active']" [routerLink]="['/maestros/zonas']" *ngIf="userService.hasRole([1])"><mat-icon>place</mat-icon>Zonas</a>
          </ng-container>
        </mat-menu>

        <mat-menu #admin2Menu="matMenu">
          <a mat-menu-item (click)="changePassword()"><mat-icon>lock</mat-icon> Cambiar contraseña</a>
          <a mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Salir</a>
        </mat-menu>

      </div>
    </ng-container>
    <img src="/assets/sf.svg" class="sf" />
  </mat-toolbar-row>
</mat-toolbar>
<div class="lazy-loading">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>
<router-outlet></router-outlet>
