import { Component } from '@angular/core';
import { Constants } from './app.settings';
import { UserService } from './services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordDialog } from './dialogs/password.dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = Constants.TITLE;
  loading = true;

  constructor(
    private dialog: MatDialog,
    public userService: UserService,
    private snackBar: MatSnackBar,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon("cow", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/cow.svg"));
    this.matIconRegistry.addSvgIcon("planning", this.domSanitizer.bypassSecurityTrustResourceUrl("assets/icons/planning.svg"));
    this.userService.updateUser();

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
      }
    });
  }

  /**Cambio de contraseña */
  changePassword(): void {
    this.dialog.open(PasswordDialog, {
      autoFocus: true
    });
  }

  /**Deslogeo del usuario */
  logout(): void {
    this.userService.logout()
      .then(() => this.router.navigate(['ingreso']))
      .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }
}
