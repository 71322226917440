import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaddockModel, PaddockFilter, PaddockFields } from '../models/paddock.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';

/**Clase para el manejo de los potreros */
@Injectable({
  providedIn: 'root'
})
export class PaddockService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de los pluviómetros de un establecimiento
   */
  listAll(establishmentId: number): Promise<PaddockModel[]> {
    return this.http.get<PaddockModel[]>('/api/paddock/list/' + establishmentId)
      .toPromise();
  }

  /**
  * Devuelve la lista de los potreros
  * @param from Registro desdel cual obtener los datos
  * @param length Cantidad de registros a obtener
  * @param order Campo a utilizar para el orden
  * @param orderAsc Establece si el orden es ascendente
  * @param filter Filtros a utilizar
  * @param draw Identificador de la llamada
  */
  list(from: number, length: number, order: PaddockFields, orderAsc: boolean, filter: PaddockFilter, draw?: number): Promise<ResponseDataModel<PaddockModel>> {
    const data = new QueryDataModel<PaddockFilter, PaddockFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<PaddockModel>>('/api/paddock/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina un potreros
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/paddock/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos del potrero
   * @param id Identificador
   */
  get(id: number): Promise<PaddockModel> {
    return this.http.get<PaddockModel>('/api/paddock/' + id)
      .toPromise();
  }

  /**
   * Graba los datos del potrero
   * @param data Datos a guardar
   */
  save(data: PaddockModel): Promise<boolean> {
    return this.http.put<boolean>('/api/paddock', data)
      .toPromise();
  }
}
