import { Component, OnInit, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { merge, of as observableOf, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime, skip, filter } from 'rxjs/operators';
import { AnimalFilter, AnimalCategoryModel } from '../../models/animal.model';
import { RodeoModel } from '../../models/rodeo.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RodeoService } from '../../services/rodeo.service';
import { AnimalService } from '../../services/animal.service';
import { Constants } from '../../app.settings';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'dashboard-animals',
  templateUrl: './animals.component.html',
  styleUrls: ['./animals.component.scss']
})
export class AnimalsComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['paddock', 'category', 'rodeo', 'avgWeight', 'quantityInRodeo'];
  data = [];
  resultsLength = 0;
  isLoadingResults = false;
  hasError = false;
  draw: number;
  refresh = new EventEmitter();
  subs: Subscription;
  filter = new AnimalFilter();
  rodeos: RodeoModel[];
  animalCategories: AnimalCategoryModel[];

  @ViewChild(NgForm, { static: true }) filterForm: NgForm;

  constructor(
    private animalService: AnimalService,
    private snackBar: MatSnackBar,
    private rodeoService: RodeoService
  ) { }

  @Input('establishmentId')
  set paramEstablishment(val: number) {
    if (val !== this.filter.establishmentId) {
      this.filter.establishmentId = val;
      this.rodeoService.listAll(val)
        .then(data => this.rodeos = data)
        .catch(() => this.snackBar.open(Constants.ERROR_COMM));
      this.refreshData();
    }
  }

  @Input('paddockId')
  set paramPaddockId(val: number) {
    if (val !== this.filter.paddockId) {
      this.filter.paddockId = val;
      this.refreshData();
    }
  }

  ngOnInit(): void {
    this.animalService.listCategories()
      .then(data => this.animalCategories = data)
      .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  refreshData() {
    this.refresh.emit();
  }

  ngAfterViewInit() {
    //Capturo los eventos del filtro y, si está en otra página que no
    //sea la primera, lo muevo a la primera y cancelo el observable
    const filterRx = this.filterForm.valueChanges.pipe(
      debounceTime(500),
      filter(() => {
        return true;
      }));

    this.subs = merge(filterRx.pipe(skip(1)), this.refresh)
      .pipe(
        startWith({}),
        filter(() => typeof this.filter.establishmentId !== 'undefined' && this.filter.establishmentId !== null && this.filter.establishmentId !== 0),
        switchMap(() => {
          this.isLoadingResults = true;
          this.draw = (new Date()).getTime();
          return this.animalService.getForDashboard(this.filter);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.hasError = false;
          return data;
        }),
        catchError(() => {
          this.hasError = true;
          this.snackBar.open(Constants.ERROR_COMM);
          return observableOf([]);
        })
      ).subscribe(data => this.data = data);
  }

}
