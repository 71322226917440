import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { PaddockService } from '../../services/paddock.service';
import { PaddockModel } from '../../models/paddock.model';
import { EstablishmentModel } from '../../models/establishment.model';
import { EstablishmentService } from '../../services/establishment.service';
import { RegionModel } from '../../models/region.model';
import { RegionService } from '../../services/region.service';
import { ZoneModel } from '../../models/zone.model';
import { ZoneService } from '../../services/zone.service';
import { Constants } from '../../app.settings';
import { PlanningState } from '../../models/planning.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  isLoadingResults = false;
  hasError = false;
  regionId: number;
  zoneId: number;
  establishmentId: number;
  paddockId: number;
  ngForm: NgForm;

  regions: RegionModel[];
  zones: ZoneModel[];
  establishments: EstablishmentModel[];
  paddocks: PaddockModel[];
  ExecutionPlanning: PlanningState;

  @ViewChild(NgForm) filterForm: NgForm;

  constructor(
    private regionService: RegionService,
    private zoneService: ZoneService,
    private establishmentService: EstablishmentService,
    private paddockService: PaddockService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.regionService.listForDashboard()
      .then(res => this.regions = res)
      .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }

  ngAfterViewInit() {

  }

  changeRegion(value: number = null): void {
    this.filterForm.controls.zone.setValue(null);
    this.filterForm.controls.establishment.setValue(null);
    this.filterForm.controls.paddock.setValue(null);
    if (value === null)
      this.zones = [];
    else
      this.zoneService.listForDashboard(value)
        .then(res => this.zones = res)
        .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }

  changeZone(value: number = null): void {
    this.filterForm.controls.establishment.setValue(null);
    this.filterForm.controls.paddock.setValue(null);
    if (value === null)
      this.establishments = [];
    else
      this.establishmentService.listForDashboard(value)
        .then(res => this.establishments = res)
        .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }

  changeEstablishment(value: number = null): void {
    this.filterForm.controls.paddock.setValue(null);
    if (value === null) {
      this.paddocks = [];
    } else
      this.paddockService.listAll(value)
        .then(res => this.paddocks = res)
        .catch(() => this.snackBar.open(Constants.ERROR_COMM));
  }
}
