import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlanningModel, PlanningFilter, PlanningFields } from '../models/planning.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';
import { AvailabilityModel, AvailabilityDashboardFilter, AvailabilitySelection } from '../models/availability.model';
import { PaddockModel } from '../models/paddock.model';

/**Resultado de la grabación */
export enum SaveResult {
  /**Se grabó todo bien 👍 */
  Ok = 0,
  /**Ya existe otra planificación con la misma descripción */
  DuplicatedDescription = -1,
  /**Las fechas se superponen con las de otra planificación */
  OverlappingDates = -2
}

/**Clase para el manejo de las planificaciones */
@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de las planificaciones
   * @param from Registro desdel cual obtener los datos
   * @param length Cantidad de registros a obtener
   * @param order Campo a utilizar para el orden
   * @param orderAsc Establece si el orden es ascendente
   * @param filter Filtros a utilizar
   * @param draw Identificador de la llamada
   */
  list(from: number, length: number, order: PlanningFields, orderAsc: boolean, filter: PlanningFilter, draw?: number): Promise<ResponseDataModel<PlanningModel>> {
    const data = new QueryDataModel<PlanningFilter, PlanningFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<PlanningModel>>('/api/planning/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina una planificación
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/planning/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos de la planificación
   * @param id Identificador
   */
  get(id: number): Promise<PlanningModel> {
    return this.http.get<PlanningModel>('/api/planning/' + id)
      .toPromise();
  }

  /**
   * Graba los datos de la planificación
   * @param data Datos a guardar
   */
  save(data: PlanningModel): Promise<SaveResult> {
    return this.http.put<SaveResult>('/api/planning', data)
      .toPromise();
  }

  /**
   * Devuelve la descripción de la planificación
   * @param id Identificador
   */
  getInfo(id: number): Promise<PlanningModel> {
    return this.http.get<PlanningModel>('/api/planning/' + id + '/info')
      .toPromise();
  }

  /**
   * Devuelve la disponibilidad por mes
   * @param planningId Identificador de la planificación
   * @param paddockId Identifica
   */
  getAvailabilities(planningId: number, paddockIds: number[]): Promise<AvailabilityModel> {
    console.log(planningId)
    console.log(paddockIds)
    const availabilitySelection: AvailabilitySelection = new AvailabilitySelection();
    availabilitySelection.planningId = planningId;
    availabilitySelection.paddockIds = paddockIds;
    return this.http.post<AvailabilityModel>('/api/planning/availabilities/', availabilitySelection)
      .toPromise();
  }

  /**
   * 
   * obtiene la info de las disponibilidades para mostrar en el dashboard
   * @param filter clase con los filtros
   */
  getAvailabilityForDashboard(filter: AvailabilityDashboardFilter): Promise<AvailabilityModel> {
    return this.http.post<AvailabilityModel>('/api/planning/dashboard/availability', filter)
      .toPromise()
      .then(data => { return data; });
  }

  /**
   * devuelve la información del progreso de la planificaion de un establecimiento para mostrar en el dashboard
   * @param establishmentId identificador del establecimiento
   */
  getProgressForDashboard(establishmentId: number): Promise<any> {
    return this.http.get<any>('/api/planning/' + establishmentId + '/dashboard/progress')
      .toPromise();
  }


  recalculate(planningId: number, paddockIds: number[]): Promise<boolean> {
    const availabilitySelection: AvailabilitySelection = new AvailabilitySelection();
    availabilitySelection.planningId = planningId;
    availabilitySelection.paddockIds = paddockIds;
    return this.http.post<boolean>('/api/planning/recalculate/', availabilitySelection)
      .toPromise();
  }

  planningenvironments(planningId: number): Promise<boolean> {
    return this.http.get<boolean>('/api/planning/' + planningId + '/planningenvironments')
      .toPromise();
  }

}
