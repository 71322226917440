import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZoneModel, ZoneFilter, ZoneFields } from '../models/zone.model';
import { ResponseDataModel, QueryDataModel } from '../models/api.model';

/**Clase para el manejo de las zonas */
@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  constructor(private http: HttpClient) { }

  /**
   * Devuelve la lista de zonas activas de una zona
   * @param regionId Identificador de la zona
   */
  listByRegion(regionId: number): Promise<ZoneModel[]> {
    return this.http.get('/api/zone/region/' + regionId)
      .toPromise()
      .then(res => { return res as ZoneModel[] });
  }

  /**
  * Devuelve la lista de las zonas
  * @param from Registro desdel cual obtener los datos
  * @param length Cantidad de registros a obtener
  * @param order Campo a utilizar para el orden
  * @param orderAsc Establece si el orden es ascendente
  * @param filter Filtros a utilizar
  * @param draw Identificador de la llamada
  */
  list(from: number, length: number, order: ZoneFields, orderAsc: boolean, filter: ZoneFilter, draw?: number): Promise<ResponseDataModel<ZoneModel>> {
    const data = new QueryDataModel<ZoneFilter, ZoneFields>();

    data.filter = filter;
    data.from = from * length;
    data.length = length;
    data.order = order;
    data.orderAsc = orderAsc;

    return this.http.post<ResponseDataModel<ZoneModel>>('/api/zone/list', data)
      .toPromise()
      .then(data => {
        data.draw = draw;
        return data;
      });
  }

  /**
   * Elimina una zona
   * @param id Identificador
   */
  delete(id: number): Promise<boolean> {
    return this.http.delete<boolean>('/api/zone/' + id)
      .toPromise();
  }

  /**
   * Devuelve los datos de la zona
   * @param id Identificador
   */
  get(id: number): Promise<ZoneModel> {
    return this.http.get<ZoneModel>('/api/zone/' + id)
      .toPromise();
  }

  /**
   * Graba los datos de la zona
   * @param data Datos a guardar
   */
  save(data: ZoneModel): Promise<boolean> {
    return this.http.put<boolean>('/api/zone', data)
      .toPromise();
  }

  /**
   * devuelve la lista de zonas activas para usar en los filtros del dashboard
   * @param regionId identificador de la región a la cual pertenecen las zonas
   */
  listForDashboard(regionId: number): Promise<ZoneModel[]> {
    return this.http.get<ZoneModel[]>('/api/zone/' + regionId + '/dashboard/list')
      .toPromise();
  }
}
